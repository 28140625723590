/**
 *
 * @module passwordBox
 * @extends module:textbox
 */
$.widget("tcg.passwordBox", $.tcg.textbox, {
    version: "1.7.10",
    options: {
        /**
         * @memberOf module:passwordBox
         * @property {boolean} [reveal=false] - 顯示密碼
         * @instance
         */
        reveal: false,
        /**
         * @memberOf module:passwordBox
         * @property {boolean} [reveal=true] - 顯示眼睛
         * @instance
         */
        showEye: true
    },
    _create: function () {
        this._super();

        //  事件監聽
        this._on(this.widget(), {
            "click .tcg-suffix-icon": function (e) {
                this.options.reveal ? this.hidePassword() : this.showPassword();
            }
        });
    },
    _init: function () {
        this._super();

        this.options.reveal = this._constrainReveal(this.options.reveal);
        this.options.showEye = this._constrainShowEye(this.options.showEye);
        if (this.options.showEye && !this.options.reveal) {
            this.suffixIconString = 'fish-eye';
        } else if (this.options.showEye && this.options.reveal) {
            this.suffixIconString = 'no-fish-eye';
        } else {
            this.suffixIconString = null;
        }

        this.refresh();
    },
    _refreshInput: function () {
        this._super();

        this.input.attr("type", this.options.reveal ? "text" : "password");

    },
    /**
     * <h4>顯示密碼</h4>
     *
     * @instance
     */
    showPassword: function () {
        this._setReveal(true);
    },
    /**
     * <h4>隱藏密碼</h4>
     *
     * @instance
     */
    hidePassword: function () {
        this._setReveal(false);
    },
    _setReveal: function (reveal) {
        this.options.reveal = this._constrainReveal(reveal);

        if (this.options.showEye && !this.options.reveal) {
            this.suffixIconString = 'fish-eye';
        } else if (this.options.showEye && this.options.reveal) {
            this.suffixIconString = 'no-fish-eye';
        } else {
            this.suffixIconString = null;
        }

        this.refresh();
    },
    _constrainReveal: function (reveal) {
        return typeof reveal == 'boolean' ? reveal : true;
    },
    _constrainShowEye: function (showEye) {
        return typeof showEye == 'boolean' ? showEye : true;
    }
});