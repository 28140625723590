(function ($) {
    var html = [];
    var out, err;

    //  generate messager html
    html.push('<div id="tcg-dev-tool" class="tcg-corner-all">');
    html.push('     <div class="console"><pre><p>{title}</p></pre></div>');
    html.push('     <input type="text" name="in" placeholder="Please enter..." class="tcg-corner-bottom"/>');
    html.push('</div>');

    /**
     * 開發工具
     *
     * @global
     *
     * @param {devToolOption} options - 控制開發工具的參數集
     */
    $.devTool = function(options){
        /**
         * @global
         * @typedef devToolOption
         *
         * @property {number} [top=50] - y軸坐標
         * @property {number} [left=50] - x軸坐標
         * @property {number} [width=400] - 寬度
         * @property {number} [height=290] - 高度
         * @property {string} [title='Hello World!'] - 標題
         */
        options = $.extend({
            top: 50,
            left: 50,
            width: 400,
            height: 290,
            title: "Hello World!"
        }, options);
        var $element, $pre;

        //  判斷是否己開啟開發者工具，若是己開啟則直接結束
        //  一次只能開啟一個
        if($("#tcg-dev-tool").length != 0){return;}

        $element = $(html.join('').replace('{title}', options.title)).appendTo("body");
        $pre = $element.find("pre");

        //  set css
        $element.css(options);
        //  draggable
        $element.draggable();

        //  rewrite out
        out = console && console.log ? console.log : function(){};
        console.log = function (message) {
            var o = typeof message == 'string' ? message : JSON.stringify(message);

            $pre.append('<p>' + o + '</p>');
            $pre.scrollTop($pre[0].scrollHeight);
            out.apply(console, arguments);
        }
        //  rewrite error
        err = console && console.error ? console.error : function () {};
        console.error = function (message) {
            var o = typeof message == 'string' ? message : JSON.stringify(message);

            $pre.append('<p class="error">' + o + '</p>');
            $pre.scrollTop($pre[0].scrollHeight);
            err.apply(console, arguments);
        }

        $element.find("input[name='in']").on("keyup", function (e) {
            if(e.keyCode == 13){
                var script = this.value.trim();

                this.value = '';

                if(script == 'cls'){
                    $element.find("pre").html("");
                }else if(script == 'close'){
                    $element.find("input[name='in']").off("keyup");
                    $element.remove();
                    console.log = out;
                    console.err = err;
                }else{
                    console.log(new Function(" return " + script)());
                }
            }
        });
    }
}(jQuery));