(function ($) {

    /**
     * 提示窗
     *
     * @global
     *
     * @param {tipOption | string} options - 控制提示窗的參數集。若參數是一字串"remove"時，則移除提示窗
     */
    $.fn.tip = function (options) {
        if (options == "remove") {
            this.siblings(".tcg-tip").removeClass("tcg-active");
            return;
        }

        /**
         * @global
         * @typedef tipOption
         *
         * @property {string} [type='info'] - 型態。兩種：訊息(info)、錯誤(error)
         * @property {string} [text=''] - 文字。
         */
        options = $.extend({
            type: "info",
            text: ""
        }, options);
        var $tip, position, html = '',
            width = this.outerWidth(), height = this.outerHeight();

        //  將父元素加上relative
        this.parent().css("position", "relative");

        //  取得或生成tip
        if (this.siblings(".tcg-tip").length == 0) {
            html += '<div class="tcg-tip">';
            html += '   <div class="tcg-tip-arrow"></div>';
            html += '   <div class="tcg-tip-text"></div>';
            html += '</div>';
            $(html).insertAfter(this);
        }
        $tip = this.siblings(".tcg-tip").removeClass("tcg-active");

        //  設定type
        $tip.toggleClass("tcg-error", options.type == "error");

        //  設定訊息
        $tip.find(".tcg-tip-text").text(options.text);
        //  設定坐落位置
        position = this.position();
        $tip.css({
            left: position.left + width + 8,
            top: position.top + (height - $tip.find(".tcg-tip-text").outerHeight()) / 2
        });
        $tip.find(".tcg-tip-arrow").css({
            top: ($tip.find(".tcg-tip-text").outerHeight() - $tip.find(".tcg-tip-arrow").outerHeight()) / 2
        });
        //  顯示
        $tip.addClass("tcg-active");
    }
}(jQuery));