/**
 *
 * @module switch
 * @extends module:_widget
 */
$.widget("tcg.switch", $.tcg._widget, {
    version: "1.7.10",
    options: {
        /**
         * @memberOf module:switch
         * @property {integer} [index=0] - 索引。
         * @instance
         */
        index: 0,
        /**
         * @memberOf module:switch
         * @property {object} [data=null] - 資料
         * @instance
         */
        data: null,
        /**
         * @memberOf module:switch
         * @property {boolean} [corner=false] - 是否圓角
         * @instance
         */
        corner: false,
        /**
         * @memberOf module:switch
         * @property {boolean} [autoChange=true] - 是否自動點擊切換
         * @instance
         */
        autoChange: true
    },
    _create: function()  {
        this._super();

        //  加載class
        this.widget().addClass("tcg-switch tcg-corner-all");
        var _this = this;
        $.each(this.options.classes, function(key, value){
            _this.widget().find("." + key).addClass(value);
        });

        //  事件監聽
        this._on(this.widget(), {
            "click": function (e) {

                //  change
                if(this.options.autoChange){
                    this.change();
                }

                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                this._trigger("click", e, {index: this.getIndex(), data: this.getSelectedData()});
            }
        });
    },
    _init: function() {

        this.options.index = this._constrainIndex(this.options.index);
        this.options.data = this._constrainData(this.options.data);
        this.options.corner = this._constrainCorner(this.options.corner);

        this.refresh();
    },
    refresh: function () {
        //  重繪
        this._refresh();
        //  根據資料顯示
        this._showData();
    },
    _refresh: function () {
        var clazz = "tcg-widget tcg-switch ";

        //  add 原本的class
        clazz += this.originClass;
        //  設定圓角
        clazz += this.options.corner ? ' tcg-round-corner' : ' tcg-corner-all';
        //  設定大小
        clazz += ' tcg-s' + this.options.size;

        //  設定class
        this.widget().attr("class", clazz);
        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
        //  串接元件
        this.widget().html('<span class="tcg-switch-handler"></span><span class="tcg-switch-text"></span>');
    },
    _destroy: function() {

    },
    _showData: function () {
        var text = this.options.data[this.options.index].text;
        //  Set status
        this.widget().attr("status", this.options.index == 0 ? 'on' : 'off');
        //  Set text
        this.widget().find(".tcg-switch-text").text(text ? text : '');
    },
    change: function () {

        this.options.index = this.options.index == 0 ? 1 : 0;

        this._showData();
        /**
         * 按鈕點擊時，觸發的事件
         *
         * @event click
         *
         * @param {Event} event - 事件
         * @param {object} data - 資料
         */
        this._trigger("change", null, {index: this.getIndex(), data: this.getSelectedData()});
    },
    /**
     * <h4>取得索引值</h4>
     *
     * @instance
     *
     * @return {integer} 索引值
     */
    getIndex: function () {
        return this.options.index;
    },
    /**
     * <h4>取得選取的資料</h4>
     *
     * @instance
     *
     * @return {object}
     */
    getSelectedData: function () {
        return this.options.data[this.options.index];
    },
    _constrainIndex: function (index) {
        index = Number(index);
        return index == 0 || index == 1 ? index * 1 : 0;
    },
    _constrainData: function (data) {
        return $.isArray(data) && data.length == 2 ? data : [{}, {}];
    },
    _constrainCorner: function (corner) {
        return typeof corner == 'boolean' ? corner : false;
    }
});