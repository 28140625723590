/**
 *
 * @module _singleSelectedWidget
 * @extends module:_selectedWidget
 */
$.widget("tcg._singleSelectedWidget", $.tcg._selectedWidget, {
    version: "1.7.10",
    options: {
        /**
         * @memberOf module:_multipleSelectedWidget
         * @property {boolean} [allowEmpty=false] - 是否允許空白
         * @instance
         */
        allowEmpty: false
    },
    _create: function()  {
        this._super();
    },
    _init: function() {
        this._super();

        this.options.allowEmpty = this._constrainAllowEmpty(this.options.allowEmpty);
    },
    /**
     * <h4>取得選取的資料</h4>
     *
     * @instance
     *
     * @return {object}
     */
    getSelectedData: function () {
        return this.options.index != -1 ? this.options.data[this.options.index] : null;
    },
    _constrainIndex: function (index) {
        if(!Number.isInteger(index)){return 0;}

        index *= 1;
        if(!this.options.allowEmpty && index < 0){
            return 0;
        }else if(this.options.allowEmpty){
            return -1;
        }else{
            return index;
        }
    },
    _constrainAllowEmpty: function (empty) {
        return typeof empty == 'boolean' ? empty : false;
    }
});
