/**
 *
 * @module textbox
 * @extends module:_widget
 */
$.widget("tcg.textbox", $.tcg._widget, {
    version: "1.7.10",
    defaultElement: '<span></span>',
    options: {
        /**
         * @memberOf module:textbox
         * @property {string} [value=''] - 數值
         * @instance
         */
        value: '',
        /**
         * @memberOf module:textbox
         * @property {string} [align='left'] - 對齊。left靠左、center置中、right靠右
         * @instance
         */
        align: '',
        /**
         * @memberOf module:textbox
         * @property {boolean} [editable=true] - 是否可編輯
         * @instance
         */
        editable: true,
        /**
         *
         * @memberOf module:textbox
         * @property {number} [width=120] - 輸入框寬度
         * @instance
         */
        width: 120,
        /**
         * @memberOf module:textbox
         * @property {string} [icon=null] - Icon
         * @instance
         */
        icon: null,
        /**
         * @memberOf module:textbox
         * @property {string} [message=null] - 提示訊息
         * @instance
         */
        message: null,
        /**
         * @memberOf module:textbox
         * @property {string} [errorMessage=null] - 錯誤訊息
         * @instance
         */
        errorMessage: null,
        /**
         * @memberOf module:textbox
         * @property {number} [messageWidth=200] - 提示訊息框的寬度
         * @instance
         */
        messageWidth: 200,
        /**
         * @memberOf module:textbox
         * @property {number} [messageWidth=200] - 提示訊息框的寬度
         * @instance
         */
        messageFocusin: true,
        /**
         * @memberOf module:textbox
         * @property {string} [placeholder=null] - Placeholder
         * @instance
         */
        placeholder: null,
        /**
         * @memberOf module:textbox
         * @property {boolean} [trim=true] - 自動截剪value兩邊的空白
         * @instance
         */
        trim: true,
        /**
         * @memberOf module:textbox
         * @property {string} [status=success|error|null] - 狀態
         * @instance
         */
        status: null
    },
    prefixIcon: null,
    suffixIcon: null,
    suffixIconString: null,
    input: null,
    tip: null,
    _create: function()  {
        this._super();

        //  生成子元素
        this.prefixIcon = $('<span class="tcg-prefix-icon"><span class="tcg-icon"></span></span>').appendTo(this.widget());
        this.input = $('<input type="text" class="tcg-input" autocomplete="off"/>').appendTo(this.widget());
        this.suffixIcon = $('<span class="tcg-suffix-icon"><span class="tcg-icon"></span></span>').appendTo(this.widget());
        this.widget().append('<span class="tcg-clearfix"></span>');
        this.tip = $('<div class="tcg-tip tcg-inner-widget"><div class="tcg-tip-arrow"></div><div class="tcg-tip-text"></div></div>').appendTo(this.widget());

        //  事件監聽
        var oldValue;
        this._on(this.widget(), {
            "focusin input.tcg-input": function(e){
                var value = this.getValue();

                //  將輸入框的值放入原始value
                this.input[0].value = value;

                //  focusin
                this.widget().addClass("focus");

                //  select all text
                if(!this.input.attr("readonly")){
                    this.input.select();
                }

                //  是否顯示提示訊息
                if(this.options.messageFocusin && this.options.message){
                    this._refreshTip(this.options.message, false);
                }

                /**
                 * 鎖定輸入框時，觸發的事件
                 *
                 * @event focusin
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料{value}
                 */
                this._trigger("focusin", e, {value:this.getValue()});
            },
            "focusout input.tcg-input": function(e){

                //  reset value
                this.setValue(this.input[0].value);

                //  focusout
                this.widget().removeClass("focus");

                //  是否隱藏提示訊息
                if(this.options.messageFocusin && this.options.message && !this.tip.hasClass("tcg-error")){
                    this.tip.removeClass('tcg-active');
                }

                /**
                 * 離開輸入框時，觸發的事件
                 *
                 * @event focusout
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料{value}
                 */
                this._trigger("focusout", e, {value:this.getValue()});
            },
            "keydown input.tcg-input": function (e) {
                /**
                 * 離開鍵盤時，觸發的事件
                 *
                 * @event keydown
                 *
                 * @param {Event} event - 事件
                 */
                this._trigger("keydown", e, {value:this.input[0].value});
            },
            "keyup input.tcg-input": function (e) {
                /**
                 * 按下鍵盤時，觸發的事件
                 *
                 * @event keyup
                 *
                 * @param {Event} event - 事件
                 */
                this._trigger("keyup", e, {value:this.input[0].value});
                /**
                 * 按下鍵盤時，觸發的事件
                 *
                 * @event keyup
                 *
                 * @param {Event} event - 事件
                 */
                this._trigger("change", e, {value:this.input[0].value});

                if(e.keyCode == 13){
                    this.setValue(this.input[0].value)

                    /**
                     * 按下enter時，觸發的事件
                     *
                     * @event enter
                     *
                     * @param {Event} event - 事件
                     */
                    this._trigger("enter", e, {value: this.getValue()});
                }
            },
            "click .tcg-suffix-icon": function (e) {
                //  狀態為錯誤
                if(this.options.status == 'error'){
                    this.options.value = ''; this.options.status = this.suffixIconString = null;
                    this.refresh();
                }
            }
        });
    },
    _init: function() {
        this._super();

        this.options.editable = this._constrainEditable(this.options.editable);
        this.options.trim = this._constrainTrim(this.options.trim);
        this.options.value = this._constrainValue(this.options.value);
        this.options.align = this._constrainAlign(this.options.align);
        this.options.status = this._constrainStatus(this.options.status);
        this.suffixIconString = this.options.status ? this.options.status : null;
        this.options.width = this._constrainWidth(this.options.width);
        this.options.messageWidth = this._constrainMessageWidth(this.options.messageWidth);

        this.refresh();
    },
    refresh: function () {
        var clazz = 'tcg-widget tcg-textbox tcg-corner-all ';

        clazz += this.originClass + ' ';

        this.widget().attr("class", clazz);
        this.widget().attr("style", this.originStyle);

        //  設定高度
        this.options.size != null ? this.widget().addClass("tcg-s" + this.options.size) : this.widget().removeClass(this.allSizeClasses.join(" "));
        //  設定狀態
        if(this.options.status){
            this.widget().addClass('tcg-' + this.options.status);
        }else{
            this.widget().removeClass('tcg-success tcg-error');
        }

        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);

        this._refreshIcon();

        this._refreshInput();

        if(!this.options.messageFocusin && this.options.message){
            this._refreshTip(this.options.message, false);
        }else if(this.options.errorMessage){
            this._refreshTip(this.options.errorMessage, true);
        }
    },
    _refreshIcon: function () {
        //  設定class
        this.prefixIcon.find(".tcg-icon").attr("class", 'tcg-icon ' + (this.options.icon ? 'tcg-icon-' + this.options.icon : ''));
        this.suffixIcon.find(".tcg-icon").attr("class", 'tcg-icon ' + (this.suffixIconString ? 'tcg-icon-' + this.suffixIconString : ''));

        //  設定是否顯示
        this.options.icon ? this.prefixIcon.show() : this.prefixIcon.hide();
        this.suffixIconString ? this.suffixIcon.show() : this.suffixIcon.hide();

        var prefixWidth = this.prefixIcon.outerWidth(),
            prefixHeight = this.prefixIcon.outerHeight(),
            suffixWidth = this.suffixIcon.outerWidth(),
            suffixHeight = this.suffixIcon.outerHeight(),
            $prefixIcon = this.prefixIcon.find(".tcg-icon"),
            $suffixIcon = this.suffixIcon.find(".tcg-icon"),
            prefixIconWidth = $prefixIcon.outerWidth(),
            prefixIconHeight = $prefixIcon.outerHeight(),
            suffixIconWidth = $suffixIcon.outerWidth(),
            suffixIconHeight = $suffixIcon.outerHeight();

        $prefixIcon.css({
            top: (prefixHeight - prefixIconHeight) / 2,
            left: (prefixWidth - prefixIconWidth) / 2
        });
        $suffixIcon.css({
            top: (suffixHeight - suffixIconHeight) / 2,
            left: (suffixWidth - suffixIconWidth) / 2
        });
    },
    _refreshInput: function () {
        var prefixWidth = this.prefixIcon.outerWidth();
        var suffixWidth = this.suffixIcon.outerWidth();
        var borderWidth = this.widget().outerWidth() - this.widget().width();

        //  設定寬度
        this.input.css("width", this.options.width - borderWidth - (this.options.icon ? prefixWidth : 0) - (this.suffixIconString ? suffixWidth : 0));
        //  設定對齊
        this.input.css("text-align", this.options.align);
        //  是否可編輯
        this.input.attr("readonly", !this.options.editable);
        //  是否禁用
        this.input.attr("disabled", this.options.disabled);
        //  設定提示
        this.input.attr("placeholder", this.options.placeholder);
        //  設定數值
        this._setValue(this.options.value);
    },
    _refreshTip: function (message, isError) {
        this.tip.addClass('tcg-active');
        this.tip.toggleClass('tcg-error', isError);

        //  arrow
        this.tip.find(".tcg-tip-arrow").css({
            top: (this.widget().outerHeight() - this.tip.find(".tcg-tip-arrow").outerHeight()) / 2
        });

        //  text
        this.tip.find('.tcg-tip-text').css('width', this.options.messageWidth);
        this.tip.find('.tcg-tip-text').html(message);
        this._delay(function(){
            this.tip.find('.tcg-tip-text').css({
                top: (this.widget().outerHeight() - this.tip.find(".tcg-tip-text").outerHeight()) / 2
            });
        }, 200);
    },
    _destroy: function() {
        this.element.show();
        this.widget().remove();
    },
    _setText: function(text){
        this.input[0].value = text;
    },
    _setValue: function (value) {
        this.options.value = this._constrainValue(value);
        this._setText(this.options.value);
    },
    /**
     * <h4>設定數值</h4>
     *
     * @instance
     *
     * @param {string} value - 數值
     */
    setValue: function (value) {
        this._setValue(value);

        /**
         * 日期改變時，觸發的事件
         *
         * @event change
         *
         * @param {Event} event - 事件
         * @param {object} data - 資料
         */
        this._trigger("change", null, this.options.value);
    },
    /**
     * <h4>取得數值</h4>
     *
     * @instance
     *
     * @return {string} 數值
     */
    getValue: function () {
        return this.options.trim ? this.options.value.trim() : this.options.value;
    },
    /**
     * <h4>清除提示訊息</h4>
     *
     * @instance
     */
    clearMessage: function () {
        this.options.message = null;
        this.tip.removeClass("tcg-active");
    },
    /**
     * <h4>設定提示訊息</h4>
     *
     * @param {string} message - 提示訊息
     *
     * @instance
     */
    setMessage: function (message) {
        this.options.message = message;
        if(!this.options.messageFocusin && message){
            this._refreshTip(message, false);
        }
    },
    /**
     * <h4>清除錯誤訊息</h4>
     *
     * @instance
     */
    clearError: function () {
        this.options.errorMessage = null;

        if(this.tip.hasClass('tcg-error')){
            this.tip.removeClass("tcg-active,tcg-error");
        }
    },
    /**
     * <h4>設定錯誤訊息</h4>
     *
     * @param {string} errorMessage - 錯誤訊息
     *
     * @instance
     */
    setErrorMessage: function (errorMessage) {
        this.options.errorMessage = errorMessage;
        this._refreshTip(errorMessage, true);
    },
    _constrainValue: function (value) {
        return typeof value != undefined && value != null && value != NaN ? value : '';
    },
    _constrainAlign: function (align) {
        var ALIGN = ["left", "center", "right"];
        return ALIGN.indexOf(align) != -1 ? align : ALIGN[0];
    },
    _constrainStatus: function (status) {
        return ["success","error"].indexOf(status) != -1 ? status : null;
    },
    _constrainEditable: function (editable) {
        return typeof editable == "boolean" ? editable : true;
    },
    _constrainTrim: function (trim) {
        return typeof trim == "boolean" ? trim : true;
    },
    _constrainWidth: function (width) {
        return $.isNumeric(width) ? width : 120;
    },
    _constrainMessageWidth: function (width) {
        return $.isNumeric(width) ? width : 200;
    }
});