/**
 * @module button
 * @extends module:_widget
 */
$.widget("tcg.button", $.tcg._widget, {
    version: "1.7.10",
    defaultElement: "<span>",
    options: {
    	/**
         * @memberOf module:button
         * @property {string} [text=''] - 文字
         * @instance
         */
    	text: '',
    	/**
         * @memberOf module:button
         * @property {boolean} [processing=false] - 按鈕加載鎖定中
         * @instance
         */
    	processing: false,
    	/**
         * @memberOf module:button
         * @property {string} [color=null] - 按鈕顏色
         * @instance
         */
    	color: null,
    	/**
         * @memberOf module:button
         * @property {boolean} [corner=false] - 按鈕是否圓角
         * @instance
         */
    	corner: false,
        /**
         * @memberOf module:button
         * @property {string} [icon=null] - Icon
         * @instance
         */
        icon: null
    },
    _create: function()  {
        this._super();

        //  init text
        this.options.text = this.options.text ? this.options.text : this.element.text();

        //  事件監聽
        this._on(this.widget(), {
            "click": function (e) {

                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
                 */
                this._trigger("click", e);
            }
        });
    },
    _init: function() {
        this._super();

        this.options.icon = this._constrainIcon(this.options.icon);
        this.options.corner = this._constrainCorner(this.options.corner);

        this.refresh();
    },
    refresh: function () {
        var clazz = "tcg-widget tcg-button ";
        var text = '';

        clazz += this.originClass;
        //  設定圓角
        clazz += this.options.corner ? ' tcg-round-corner' : ' tcg-corner-all';
    	//	設定顏色
        clazz += this.options.color ? ' tcg-' + this.options.color : '';
        //  設定大小
        clazz += ' tcg-s' + this.options.size;
        //  是否加載中
        clazz += this.options.processing ? ' tcg-processing' : '';

        //  是否有icon
        text += this.options.icon != null ? '<span class="tcg-icon icon-' + this.options.icon + '"></span>' : '';
        //  是否有文字
        text += this.options.text ? this.options.text : '';
        //  是否加載中
        text += '<span class="tcg-loading"></span>';

        //  設定class
        this.widget().attr("class", clazz);
        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
    	//	設定寬度
        this.widget().css("width", this.options.width);
    	//	文字
        this.widget().html(text);
    },
    _destroy: function() {
		this._removeClass("tcg-button").empty();
    },
    /**
     * <h4>取得按鈕文字</h4>
     *
     * @instance
     *
     * @return {string} 文字
     */
    getText: function () {
    	return this.options.text;
    },
    /**
     * <h4>判斷是否加載鎖定中</h4>
     *
     * @instance
     *
     * @return {boolean} 是否加載鎖定中
     */
    isProcessing: function () {
    	return this.options.processing;
    },
    /**
     * <h4>取得按鈕顏色</h4>
     *
     * @instance
     *
     * @return {string} 按鈕顏色
     */
    getColor: function () {
        return this.options.color;
    },
    _constrainIcon: function (icon) {
        return typeof icon == 'string' ? icon : null;
    },
    _constrainCorner: function (corner) {
        return typeof corner == 'boolean' ? corner : false;
    }
});
