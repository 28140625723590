/**
 * @module buttonGroup
 * @extends module:_multipleSelectedWidget
 */
$.widget("tcg.buttonGroup", $.tcg._multipleSelectedWidget, {
    version: "1.7.10",
    defaultElement: "<span>",
    options: {
        /**
         * @memberOf module:buttonGroup
         * @property {string} [color=null] - 按鈕顏色
         * @instance
         */
        color: null,
        /**
         * @memberOf module:buttonGroup
         * @property {boolean} [corner=false] - 按鈕是否圓角
         * @instance
         */
        corner: false,
        /**
         * @memberOf module:buttonGroup
         * @property {boolean} [spacing=2] - 按鈕間距
         * @instance
         */
        spacing: 2,
        /**
         * @memberOf module:buttonGroup
         * @property {number} [width=null] - 按鈕寬度
         * @instance
         */
        width: null,
        /**
         * @memberOf module:buttonGroup
         * @property {number} [fontSize=null] - 文字大小
         * @instance
         */
        fontSize: null
    },
    _create: function () {
        this._super();

        //  加載class
        this.widget().addClass("tcg-button-group tcg-corner-all");
        var _this = this;
        $.each(this.options.classes, function (key, value) {
            _this.widget().find("." + key).addClass(value);
        });

        //  事件監聽
        this._on(this.widget(), {
            "click .tcg-button": function (e) {
                var index = Number(e.currentTarget.dataset.index),
                    i;

                //  1.單選，並且選擇與當前index不同
                //  2.複選
                if (!this.options.multiple && index != this.options.index) {
                    this.options.index = index;
                    this.refresh();
                } else if (this.options.multiple) {
                    i = this.options.index.indexOf(index);

                    //  1.複選，當前index不存在
                    //  2.複選，當前index存在且index長度大於1
                    //  3.複選，當前index存在且允許空白
                    if (i == -1) {
                        this.options.index.push(index);
                    } else if (i != -1 && this.options.index.length > 1) {
                        this.options.index.splice(i, 1);
                    } else if (i != -1 && this.options.allowEmpty) {
                        this.options.index.splice(i, 1);
                    }
                    this.refresh();
                }

                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                this._trigger("click", e, {
                    index: this.getIndex(),
                    data: this.getSelectedData()
                });
            }
        });
    },
    _init: function () {
        this._super();

        this.options.spacing = this._constrainSpacing(this.options.spacing);
        this.options.corner = this._constrainCorner(this.options.corner);

        this.refresh();
    },
    refresh: function () {

        //  重繪
        this._refresh();

        //  重繪按鈕
        this._refreshButton();
    },
    _refresh: function () {
        var clazz = "tcg-widget tcg-button-group ";

        //  add 原本的class
        clazz += this.originClass;
        //  設定圓角
        clazz += this.options.corner ? ' tcg-round-corner' : ' tcg-corner-all';
        //  設定大小
        clazz += ' tcg-s' + this.options.size;

        //  設定class
        this.widget().attr("class", clazz);
        //  是否禁用
        this.widget().attr("disabled", this.options.disabled);
    },
    _refreshButton: function () {
        var html = '';
        var data = this.options.data;
        var clazz = '',
            style = '',
            selected = '',
            disabled = '';

        for (var i = 0, j = data.length; i < j; i++) {
            //  class
            clazz = 'tcg-widget tcg-button tcg-s' + this.options.size;
            clazz += this.options.color ? ' tcg-' + this.options.color : '';
            clazz += this.options.corner ? ' tcg-round-corner' : ' tcg-corner-all';
            //  style
            style = '';
            style += 'margin-right:' + (i != j - 1 ? this.options.spacing + 'px;' : '0px;');
            style += this.options.width ? (' width:' + this.options.width + 'px; padding:0px;') : '';
            style += this.options.fontSize ? (' font-size:' + this.options.fontSize + 'px;') : '';
            //  selected
            if (Array.isArray(this.options.index)) {
                selected = this.options.index.indexOf(i) != -1 ? 'selected' : '';
            } else {
                selected = this.options.index == i ? 'selected' : '';
            }
            //  disabled
            disabled = this.options.disabled ? ' disabled' : '';

            html += '<span class="' + clazz + '" style="' + style + '" ' + selected + disabled + ' data-index="' + i + '">' + data[i].text + '</span>';
        }
        this.widget().html(html);
    },
    _destroy: function () {
        this.widget().removeClass("tcg-button-group tcg-corner-all tcg-round-corner");
        this.widget().attr("disabled", false);
        this.widget().empty();
    },
    _constrainSpacing: function (spacing) {
        return Number.isInteger(spacing) ? spacing : 2;
    },
    _constrainCorner: function (corner) {
        return typeof corner == 'boolean' ? corner : false;
    }
});
