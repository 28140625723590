/**
 *
 * @module optionGroup
 * @extends module:_multipleSelectedWidget
 */
$.widget("tcg.optionGroup", $.tcg._multipleSelectedWidget, {
    version: "1.7.10",
    options: {
        /**
         * @memberOf module:optionGroup
         * @property {string} [layout=vertical] - 排列方式。垂直(horizontal)、水平(vertical)
         * @instance
         */
        layout: "vertical",
        /**
         * @memberOf module:optionGroup
         * @property {number} [spacing=2] - 選項間距。
         * @instance
         */
        spacing: 2
    },
    layout: ["vertical", "horizontal"],
    _create: function()  {
        this._super();

        //  加載class
        this.widget().addClass("tcg-option-group");

        //  事件監聽
        this._on(this.widget(), {
            "click .tcg-options": function (e) {
                var index = Number(e.currentTarget.dataset.index), i;

                //  1.單選，並且選擇與當前index不同
                //  2.單選，並且選擇與當前index相同
                //  3.複選
                if(!this.options.multiple && index != this.options.index){
                    this.options.index = index;
                    this.refresh();
                }else if(!this.options.multiple && index == this.options.index && this.options.allowEmpty){
                    this.options.index = -1;
                    this.refresh();
                }else if(this.options.multiple){
                    i = this.options.index.indexOf(index);

                    //  1.複選，當前index不存在
                    //  2.複選，當前index存在
                    if(i == -1){
                        this.options.index.push(index);
                    }else{
                        this.options.index.splice(i, 1);
                    }
                    this.refresh();
                }
                /**
                 * 按鈕點擊時，觸發的事件
                 *
                 * @event click
                 *
                 * @param {Event} event - 事件
                 * @param {object} data - 資料
                 */
                this._trigger("click", e, {index: this.getIndex(), data: this.getSelectedData()});
            }
        });
    },
    _init: function() {
        this._super();

        this.options.spacing = this._constrainSpacing(this.options.spacing);

        this.refresh();
    },
    refresh: function () {
        var $ul = $("<ul></ul>"), $li, html = '';
        var data = this.options.data, index = this.options.index;

        for(var i = 0, j = data.length; i < j; i++){
            $li = $("<li class='tcg-options'></li>");

            $li.addClass("tcg-" + this.options.layout);
            $li.addClass("tcg-s" + this.options.size);
            $li.attr("data-index", i);
            $li.attr("disabled", this.options.disabled);
            $li.attr("selected", ($.isArray(index) && index.indexOf(i) != -1) || ($.isNumeric(index) && index == i));
            $li.css({
                "margin-right": this.layout.indexOf(this.options.layout) == 0 && i + 1 != j ? this.options.spacing : 0,
                "margin-bottom": this.layout.indexOf(this.options.layout) == 1 && i + 1 != j ? this.options.spacing : 0,
                "width": this.options.width ? this.options.width : ''
            });

            html = '';
            html += '<div class="' + (this.options.multiple ? 'tcg-checkbox' : 'tcg-radio') + '">';
            html += '    <div class="' + (this.options.multiple ? 'tcg-icon tcg-icon-check' : 'tcg-inner') + '" ' + (this.options.disabled ? 'disabled' : '') + '></div>';
            html += '</div>';
            html += data[i].src ? '<img src="' + data[i].src + '"/>' : '<span>' + data[i].text + '</span>';
            $li.append(html);
            $ul.append($li);
        }
        this.widget().html($ul);
    },
    _destroy: function() {

    },
    _constrainLayout: function (layout) {
        return this.layout.indexOf(layout) != -1 ? layout : this.layout[0];
    },
    _constrainSpacing: function (spacing) {
        return Number.isInteger(spacing) ? spacing * 1 : 2;
    }
});
